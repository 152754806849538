import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, fontSizes, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import Link from 'components/Link'
import CarouselReviewSection from 'routes/Home/components/CarouselReviewSection'
import GetStartedSection from 'routes/Integrations/GetStartedSection'

import imageHeader from 'static/images/solutions_small_businesses/header_picture.webp'
import imageSimplifyWorkflow from 'static/images/solutions_small_businesses/simplify_workflow.webp'
import imageMonitorReputation from 'static/images/solutions_small_businesses/monitor_reputation.webp'

import biggerChallengesImageConsistency from 'static/images/solutions_small_businesses/bigger_challenges/consistency.svg'
import biggerChallengeImageTime from 'static/images/solutions_small_businesses/bigger_challenges/time.svg'
import biggerChallengeImageEaseOfUse from 'static/images/solutions_small_businesses/bigger_challenges/ease_of_use.svg'
import biggerChallengeImageBudget from 'static/images/solutions_small_businesses/bigger_challenges/budget.svg'

import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageGoogle from 'static/images/networks/google.svg'
import networkImageTripadvisor from 'static/images/networks/tripadvisor.svg'
import networkImageOpentable from 'static/images/networks/opentable.svg'
import networkImageTrustpilot from 'static/images/networks/trustpilot.svg'
import networkImageYelp from 'static/images/networks/yelp.svg'
import Button from 'components/Button'
import { pxToRem } from '../../../helpers'

const TrialButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledNetworkLink = styled(Link)`
  background: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.pill};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SIMPLIFY_WORKFLOW_ITEMS = [
  {
    title: 'Schedule content',
    description: 'Plan posts to multiple networks months in advance with a calendar and planning features.',
  },
  {
    title: 'Respond faster',
    description: 'Cut through the noise and quickly identify messages that need immediate attention with our Inbox.',
  },
  {
    title: 'Track results',
    description: 'Easily understand the impact your social media is having with easy to use customizable reports.',
  },
]

const BIGGER_CHALLENGES_ITEMS = [
  {
    picture_url: biggerChallengesImageConsistency,
    title: 'Consistency',
    description:
      'We need tools to make it easy to engage with our followers consistently to create better relationships.',
  },
  {
    picture_url: biggerChallengeImageTime,
    title: 'Time',
    description: 'We want to do more on social media but we just don’t have enough time.',
  },
  {
    picture_url: biggerChallengeImageEaseOfUse,
    title: 'Ease of use',
    description:
      'Some tools are very intimidating and take a while to learn. We are looking for something easy to use yet very powerful.',
  },
  {
    picture_url: biggerChallengeImageBudget,
    title: 'Budget',
    description: 'We don’t have thousands and even hundreds of dollars to pay for a platform.',
  },
]

const NETWORKS = [
  { image: networkImageFacebook, pagePath: '/integrations/facebook' },
  { image: networkImageGoogle, pagePath: '/integrations/google' },
  { image: networkImageTripadvisor, pagePath: '/integrations/tripadvisor' },
  { image: networkImageOpentable, pagePath: '/integrations/opentable' },
  { image: networkImageTrustpilot, pagePath: '/integrations/trustpilot' },
  { image: networkImageYelp, pagePath: '/integrations/yelp' },
]

const description =
  'Best-in-class social media management tools to help a business, of any size, extract real business value out of social media and turn their brand into a household name.'
const SolutionsSmallBusinesses = () => {
  return (
    <App fullHeader>
      <SEO title="Social Media Solutions for Businesses" description={description} path="/small-medium-businesses/" />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '485px' }}>
                  <H1
                    fontSize="4xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Social Media Solutions for{' '}
                    <H1
                      as="span"
                      fontSize="4xl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      Businesses&nbsp;⚡
                    </H1>
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      {description}
                    </H2>
                  </Flex>

                  <Flex mt="m" pt="m" mb="m">
                    <Flex
                      height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}
                      width="100%"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <TrialButton as="a" href="/pricing" target="_blank" rel="noreferrer noopener nofollow">
                        Start your free trial
                      </TrialButton>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="467px"
                  alt="Effectively Manage Multi-Location Brands"
                />
              </Flex>
            </Grid>

            <Flex
              mt={{ mobile: 'l', tablet: 'm' }}
              pt="m"
              flexDirection="column"
              alignItems="center"
              mb={{ mobile: 'l', desktop: 'l' }}
            >
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" maxWidth="920px" mx="auto">
            <Grid gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', desktop: 'auto 255px' }} gridGap="l">
              <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '564px' }}>
                <Text
                  fontWeight="bold"
                  fontSize="3xl"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Smaller teams,
                  <br />
                  <Text as="span" color="primary" fontWeight="bold" fontSize="3xl">
                    bigger challenges
                  </Text>
                </Text>
                <Text
                  mt="m"
                  pt="s"
                  color="secondaryText"
                  fontSize="l"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Vista Social helps thousands of small and medium-sized businesses simplify managing social media and
                  build stronger relationships with their customers and followers. While every business is unique, these
                  are the pain points Vista Social helps with:
                </Text>
              </Flex>
              <Flex
                display={{ mobile: 'none', desktop: 'flex' }}
                bg={colors.primary}
                flexDirection="column"
                p="l"
                borderRadius={`${radius.l} 110px ${radius.l} ${radius.l}`}
              >
                <LocalImage
                  src={BIGGER_CHALLENGES_ITEMS[0].picture_url}
                  alt={BIGGER_CHALLENGES_ITEMS[0].title}
                  width="40px"
                  height="40px"
                />
                <Text mt="m" color="white" fontWeight="bold" fontSize="xl" textAlign="left">
                  {BIGGER_CHALLENGES_ITEMS[0].title}
                </Text>
                <Text mt="s" fontSize="m" color={COLOR_CONSTANTS.SALT} textAlign="left">
                  {BIGGER_CHALLENGES_ITEMS[0].description}
                </Text>
              </Flex>
            </Grid>
            <Grid
              mt="l"
              pt="m"
              gridTemplateColumns={{
                mobile: 'repeat(1, 1fr)',
                tablet: 'repeat(2, auto)',
                desktop: '255px 350px 255px',
              }}
              gridGap="l"
            >
              {BIGGER_CHALLENGES_ITEMS.map(({ picture_url, title, description }, index) => (
                <Flex
                  key={title}
                  display={{ mobile: 'flex', desktop: index === 0 ? 'none' : 'flex' }}
                  bg={colors.primary}
                  flexDirection="column"
                  p="l"
                  borderRadius={radius.l}
                >
                  <Flex alignItems="center">
                    <LocalImage src={picture_url} alt={title} width="40px" height="40px" />
                    <Text ml="m" color="white" fontWeight="bold" fontSize="xl">
                      {title}
                    </Text>
                  </Flex>

                  <Text fontSize="m" textAlign="left" mt="m" color={COLOR_CONSTANTS.SALT}>
                    {description}
                  </Text>
                </Flex>
              ))}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="755px" width="100%">
              <LocalImage src={imageSimplifyWorkflow} width="100%" alt="Simplify your workflow" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              maxWidth={{ mobile: '100%', desktop: '450px' }}
            >
              <Text
                fontWeight="bold"
                fontSize="3xl"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                <Text as="span" fontWeight="bold" fontSize="3xl" color="primary">
                  Simplify{' '}
                </Text>
                your workflow
              </Text>

              <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Social is time consuming, especially if you have to manage it from native platforms. Vista Social brings
                all of your social profiles into one dashboard giving you tools to quickly and efficiently take action
              </Text>
              <Grid mt="l" pt="m" gridGap="40px" gridTemplateColumns="repeat(1, 1fr)">
                {SIMPLIFY_WORKFLOW_ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg="radial-gradient(51.88% 56.86% at 31.54% 45.31%, #0063E3 36.77%, #0250C9 100%)">
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="746px" width="100%">
              <LocalImage src={imageMonitorReputation} width="100%" alt="Monitor reputation" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            >
              <Text fontWeight="bold" fontSize="4xl" color="white" textAlign={{ mobile: 'center', desktop: 'left' }}>
                Monitor reputation
              </Text>

              <Flex mt="m" pt="s" maxWidth={{ mobile: '100%', desktop: '465px' }}>
                <Text color={COLOR_CONSTANTS.SALT} fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  Monitor and track your reputation with review managements across Facebook, Google, Trip Advisor,
                  OpenTable, TrustPilot and Yelp.
                </Text>
              </Flex>
              <Flex mt="m">
                {NETWORKS.map(({ image, pagePath }, index) => (
                  <StyledNetworkLink
                    href={pagePath}
                    mr={{ mobile: index !== NETWORKS.length - 1 ? 'xs' : 0, mobileLarge: 'm' }}
                    key={pagePath}
                  >
                    <LocalImage
                      src={image}
                      alt="Your posts for the best performance on each social network."
                      width="28px"
                      height="28px"
                    />
                  </StyledNetworkLink>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <CarouselReviewSection bg={COLOR_CONSTANTS.WHITE} />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default SolutionsSmallBusinesses
